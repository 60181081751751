import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ServiceDesignPostIs = props => (
  <Layout>
    <Helmet
      title="Service Design & Post-its"
      meta={[
        {
          name: 'Article aboout the use of Post-its in Service Design',
          content: 'Articles',
        },
      ]}
      bodyAttributes={{
        class: 'articles',
      }}
    />

    <div id="main" className="alt">
      <section id="article-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.hdBanner.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>
      <div class="post-wrapper">
        <div class="post-content">
          <header>
            <h1>service design & post-its</h1>
            <h3>
              Post-its has allowed me to holistically visualise the
              interconnectedness of the different elements that make up the
              service ecosystem.
            </h3>
          </header>
          <section>
            <small>JANUARY 2016, 3 min read</small>
            <p className="full-text">
              As a product designer about to start the service design journey, I
              had mixed emotions and expectations. As a designer, I was well
              aware of the design tools and process (double diamond process) and
              ways to visualise my research/ideas and was expecting to learn new
              service design tools and felt quite prepared as to what to expect.
            </p>
            <p className="full-text">
              However, coming into the service designing world, this first thing
              I noticed was walls filled with post-it notes. That was definitely
              a surprise to me, as I had not come across the usage of post-it
              notes to this extent. I guess" Design Thinking" can bear some
              responsibility for converting conference rooms and studios being
              plastered with post-it notes after a brainstorming process. When I
              was studying as a product designer, there was not much talk about
              design thinking, however, now it's the word that is getting all of
              the attention. Especially with increasing number of organisations
              realising that creativity is the fuel for the innovation economy
              and are thus using Design Thinking approaches. As nothing says
              creativity better than a crazy colourful mess of Post-its smeared
              onto the wall rather than a bunch of people with suits sitting and
              talking around the table.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.postit1.childImageSharp.fluid} alt="" />
              </div>
              <div className="img right">
                <Img fluid={props.data.postit2.childImageSharp.fluid} alt="" />
              </div>
            </div>

            <p className="full-text">
              The first time I started using them, it felt a bit odd and
              different. I was confused as to what and how much to write in that
              small space, how big/small to write? Or should I only just draw on
              it? How do I stick it so that its clear and understandable. I
              started thinking if I really needed to use them? As I could
              continue to use my own tried and tested method of jotting down all
              my research/ ideas, and processes on a simple white plain piece of
              paper or directly on the board? However, I decided why not give
              using the post-it a honest try before disregarding it.
            </p>
            <p className="full-text">
              After using it over and over again, I began to realise the
              benefits of it. Unlike Product Design, Service Design involves
              intangible/immaterial elements where representing the design
              process becomes a key activity. And the use of post-its allowed me
              to represent it visually in a organised manner. This was
              especially useful in the research phase, as it helped to
              categorise (colour coordinate) and arrange the different research
              findings, such as stakeholders and customer journeys, in a more
              structured way. The easy flexibility of post-its also allow for a
              quick way to map and capture different elements of a service (for
              e.g. allows to plot different things in the matrix mapping process
              and to track any changes). It also allowed me to holistically
              visualise the interconnectedness (which can often be messy) of the
              different elements/systems that make up the service.
            </p>
            <div className="image">
              <Img fluid={props.data.postit3.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              Although I was already familiar with the diverging and converging
              phases of the Double Diamond Process. I had never done it with the
              use of post-its. The flexibility of post-its meant that I could
              make mistakes as anything that was written on a post-it and pasted
              on the board wasn't final as I could easily remove any of the
              post-it and replace it with another one, as compared to writing it
              down directly on the board which seemed more final (as i was
              previously used to doing it when designing products). This
              flexibility which the post-it provided has changed the way I
              design, as I am more open now to change, in terms of
              ideas/concepts. Also during the diverging phase it is very easy to
              just put all the post-it (good or bad ideas) and paste it on the
              board, and just pull off the ideas which were not good and keep
              the good ones on the board and prioritise them.
            </p>
          </section>
          <section className="takeaways">
            <span class="project-step-number">01</span>
            <h2>takeaways</h2>
            <p className="full-text">
              I also feel that the ease of using a post-it and a pen is
              beneficial to people who do not have a design background and who
              are not skilled in drawing, visualising (graphic designers),
              rendering or model-making. Overall, they are low-tech, low-cost
              tools for modelling complexity and the ways in which people,
              ideas, objects and technologies flow, which is especially vital in
              visualising all the elements that make up a service.
            </p>
            <div className="image">
              <Img fluid={props.data.postit4.childImageSharp.fluid} alt="" />
            </div>
          </section>
          {/* <section className="article-social">
            <div className="social inner">
              <p className="article-social-heading">share this post</p>
              <ul className="icons">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/sagarsatam13"
                    className="icon alt fa-twitter"
                  >
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/sagar.satam/"
                    className="icon alt fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://in.pinterest.com/sagarsatam/"
                    className="icon alt fa-pinterest"
                  >
                    <span className="label">Pinterest</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/satamsagar/"
                    className="icon alt fa-linkedin"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </section> */}
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/my-thinking"> my thinking</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <div className="tiles__next">next story</div>
        <div className="tiles__wrapper">
          <div className="tiles__content">
            <div className="tiles__info">
              <a href="/articles/designing-mobile-app-for-iot-products">
                <h2 className="tiles__title">
                  designing mobile apps for IoT products.
                </h2>
              </a>
              <small>DECEMBER 2017 - 3 mins read</small>
              <div className="tiles__text">
                The continued growth of mobile devices has led to an increase in
                ubiquitous use of mobile apps. The transient needs of consumer
                call for personalisation, instant gratification, on-demand
                services and concerns over privacy & securtiy; which has brought
                upon unique challenges when it comes to designing UX/UI for
                these apps.
              </div>
            </div>
          </div>
          <div className="tiles__image">
            <a href="/articles/designing-mobile-app-for-iot-products">
              <Img fluid={props.data.otherarticle.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignPostIs

export const fluidPostItImage = graphql`
  fragment fluidPostItImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hdBanner: file(relativePath: { eq: "articles/postit/cover-posit-it.png" }) {
      ...fluidPostItImage
    }
    postit1: file(relativePath: { eq: "articles/postit/postit1.png" }) {
      ...fluidPostItImage
    }
    postit2: file(relativePath: { eq: "articles/postit/postit2.png" }) {
      ...fluidPostItImage
    }
    postit3: file(relativePath: { eq: "articles/postit/postit3.png" }) {
      ...fluidPostItImage
    }
    postit4: file(relativePath: { eq: "articles/postit/postit4.png" }) {
      ...fluidPostItImage
    }
    otherarticle: file(relativePath: { eq: "projects/appshow.png" }) {
      ...fluidPostItImage
    }
  }
`
